<template>
  <b-modal 
    id="create-kit-template-modal" 
    :title="title ?? 'Criar novo kit'"
    size="lg"
    @hide="onClose"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="4">
          <label>
            Código do kit
          </label>
          <b-form-input
            id="kit-code"
            type="text"
            v-model="form.code"
            placeholder="00001"
            required
          />
        </b-col>
        <b-col cols="8">
          <label>
            Nome do kit
          </label>
          <b-form-input
            id="kit-name"
            type="text"
            v-model="form.name"
            placeholder="Descrever"
            required
          />
        </b-col>
      </b-row>
      <hr />
      <section class="products-section">
        <b-row 
          class="mt-2"
          v-for="(product, index) in form.products" 
          :key="index"
        >
          <b-col cols="8">
            <Autocomplete
              v-model="product.product_id"
              :options="productOptions"
              placeholder="Digitar código | Bipar"
              @input="inputProduct(index, $event)"
              @update="searchProduct(index, $event)"
              debounce="1000"  
            />
          </b-col>
          <b-col cols="3">
            <div class="product-input">
              <b-form-input
                type="number"
                min="1"
                :class="{'is-invalid': product?.quantity <= 0}"
                v-model="product.quantity"
                :id="`kit-product-quantity-${index}`"
                placeholder="Quantidade"
              />
              <Barcode v-show="!product?.name" class="barcode"/>
            </div>
          </b-col>
          <b-col cols="1" class="text-center" align-self="center">
            <Trash 
              class="icon trash"
              @click="removeProduct(index)"
            />
          </b-col>
        </b-row>
      </section>
      <b-row class="text-align-center mt-3">
        <b-col cols="12">
          <b-button
            variant="outline-primary"
            @click="addProduct"
          >
            Adicionar produto
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <div slot="modal-footer" class="w-100">
      <b-btn 
        class="float-right" 
        variant="primary" 
        :disabled="!isValidForm()"
        @click="handleSave"
      >
        Salvar
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'create-template-modal',
  components: {
    Trash: () => import('@/assets/icons/trash.svg'), 
    Barcode: () => import('@/assets/icons/barcode.svg'),
    Autocomplete: () => import('@/components/Autocomplete.vue'),
  },
  props: {
    title: String,
    kitTemplate: Object || null,
  },
  data () {
    return {
      form: {
        code: null,
        name: null,
        clinic_id: JSON.parse(localStorage.getItem('clinic')).id,
        products: [],
      },
      clinic: JSON.parse(localStorage.getItem('clinic')),
      productOptions: [],
      itemsToDelete: [],
    }
  },
  methods: {
    onClose () {
      this.productOptions = []
      this.resetForm()
      this.$emit('close')
    },
    resetForm () {
      this.form = this.getDefaultForm()
    },
    getDefaultForm () {
      return {
        code: null,
        name: null,
        clinic_id: this.clinic.id,
        products: [],
      }
    },
    isValidForm () {
      const validProducts = this.form.products?.length && this.form.products.every(product => {
        return product?.product_id && product?.quantity > 0
      })
      return (
        this.form?.code 
        && this.form?.name?.length
        && validProducts
      )
    },
    addProduct () {
      this.form.products.push({
        id: null,
        product_id: null,
        barcode: null,
        name: null,
        quantity: 1,
      })
    },
    async searchProduct (index, code) {
      if (!code?.length) return;

      try {
        const res = await this.api.searchProducts(this.clinic.id, code) 

        const newOptions = res.data.map(product => ({
          value: product?.id,
          barcode: product?.barcode,
          name: product?.name,
          label: `${product?.barcode ? product.barcode+' -' : ''} ${product?.name}`,
          disabled: this.form.products.some(prod => prod?.product_id === product?.id),
        }))

        this.productOptions = this.productOptions.concat(newOptions)
        this.productOptions = this.productOptions.filter((option, index, self) => {
          return self.findIndex(opt => opt.value === option.value) === index
        })

        this.productOptions = this.productOptions.filter(option => option.value && option.name)

      } catch (error) {
        this.$toast.error('Não foi possível encontrar o produto, contato o suporte se o erro persistir.')
      }
    },
    setProductAsAvailable (formProductIndex) {
      const optionIndex = this.productOptions.findIndex(option => 
        option.name === this.form.products[formProductIndex].name
          && option.barcode === this.form.products[formProductIndex].barcode
      )

      if(this.productOptions[optionIndex])
        this.$set(this.productOptions[optionIndex], 'disabled', false)
    },
    removeProduct (index) {
      if (this.form?.products[index]?.pivot?.id) 
        this.itemsToDelete.push(this.form?.products[index]?.pivot?.id)

      this.setProductAsAvailable(index)

      this.form.products.splice(index, 1)
    },
    inputProduct (index, productId) {
      if (!productId) {
        this.setProductAsAvailable(index)

        this.form.products[index].product_id = null
        this.form.products[index].barcode = null
        this.form.products[index].name = null
        return;
      }

      const productOption = this.productOptions.find(option => option.value === productId)
      if (productOption)
        this.$set(productOption, 'disabled', true)

      this.form.products[index].product_id = productId
      this.form.products[index].barcode = productOption?.barcode
      this.form.products[index].name = productOption?.name 
    },
    async handleSave () {
      if (this.form?.id) 
        await this.updateTemplate()
      else
        await this.saveTemplate()
    },
    async saveTemplate () {
      if (!this.isValidForm()) return;

      const loading = this.$loading.show()
      try {
        await this.api.createKitTemplate(this.form)
        this.$toast.success('Kit salvo com sucesso!')
        this.$emit('save')
        this.resetForm()
      } catch (err) {
        this.$toast.error(err?.response?.data?.message)
      } finally {
        loading.hide()
      }
    },
    async updateTemplate () {
      if (!this.isValidForm()) return;

      const loading = this.$loading.show()
      try {
        const data = {
          ...this.form,
          clinic_id: this.clinic.id,
          template_products_to_delete: this.itemsToDelete
        }

        await this.api.updateKitTemplate(this.form.id, data)
        this.$toast.success('Kit atualizado com sucesso!')
        this.$emit('save')
        this.resetForm()
      } catch (err) {
        this.$toast.error(err?.response?.data?.message)
      } finally {
        loading.hide()
      }
    },
  },

  watch: {
    kitTemplate: {
      handler (value) {
        if (value) {
          this.productOptions = this.kitTemplate.products.map(product => ({
            value: product?.product_id,
            barcode: product?.barcode,
            name: product?.name,
            disabled: this.kitTemplate.products.some(prod => prod?.product_id === product?.product_id),
            label: `${product?.barcode ? product?.barcode+' -' : ''} ${product?.name}`,
          }))
          this.productOptions = this.productOptions.filter(option => option.value && option.name)
          this.form = this.kitTemplate
        } else { 
          this.resetForm()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.trash {
  stroke: var(--states-red-soft);
}

.product-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  input {
    position: relative;
  }

  .barcode {
    position: absolute;
    margin-left: -70px;
    transform: scale(0.7);
  }
  
}
</style>